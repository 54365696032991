import React, { useState, useContext, useRef } from 'react'
import { graphql } from 'gatsby'
import Layout, { NavFocusContext } from '../components/Layout'
import { useWindowDimensions, useClickOutside } from '../utils/hooks'
import { Helmet } from 'react-helmet'
import CarouselRow from '../components/CarouselRow'
import HighlightVideo from '../components/HighlightVideo'
import CarouselWithSwiper from '../components/CarouselWithSwiper'
import { FullscreenVideoProvider } from '../utils/useFullscreenVideo'

const DirectorPageTemplate = ({
	name,
	videos,
	descriptionEn,
	descriptionPt,
}) => {
	const [showBio, setShowBio] = useState(false)
	const [language, setLanguage] = useState('PT')
	const screen = useWindowDimensions()
	const isNavFocused = useContext(NavFocusContext)
	const [currentlyPlaying, setCurrentlyPlaying] = useState()
	const [isFullScreen, setFullScreen] = useState(false)
	const [fullscreenRequired, requireFullscreen] = useState(false)

	const bioEl = useRef(null)

	// Fecha o modal quando há clique fora dele ou tecla esc
	const handleClickOutside = () => {
		setShowBio(false)
	}
	useClickOutside(bioEl, handleClickOutside, showBio)

	const [highlightVideo, ...gridVideos] = videos

	return (
		<>
			{screen.width < 768 ? (
				<FullscreenVideoProvider>
					<div className="col-span-4 col-start-1 row-start-1 row-end-4 w-full">
						<CarouselWithSwiper videos={videos} />
					</div>
				</FullscreenVideoProvider>
			) : (
				<>
					<div
						ref={bioEl}
						className={`
						${isNavFocused && 'hidden'}
						z-10 col-start-1 col-end-3
						row-start-2 -mt-3 ml-16
						grid max-w-2xl grid-rows-bio p-4 px-8
						pt-4 font-light lg:px-4
						${!showBio ? 'h-0' : 'h-4/6 bg-gray-800 bg-opacity-50 text-white'}
						`}
					>
						<button className="max-w-max" onClick={() => setShowBio(!showBio)}>
							<h1
								className=" 
								cursor-pointer text-3xl font-normal uppercase tracking-wide text-white lg:text-6xl"
							>
								{name}
								<span className="ml-2 text-xl font-light lowercase">bio</span>
							</h1>
						</button>
						<div className={`text-xl ${!showBio && 'hidden'}`}>
							<p>{language === 'PT' ? descriptionPt : descriptionEn}</p>
						</div>
						<div
							className={`align-self-end mt-auto justify-self-end font-light ${
								!showBio && 'hidden'
							}`}
						>
							<button
								className={`${language !== 'PT' && 'font-light'}`}
								onClick={() => setLanguage('PT')}
							>
								PT
							</button>
							<span className="mx-1">-</span>
							<button
								className={`${language !== 'EN' && 'font-light'}`}
								onClick={() => setLanguage('EN')}
							>
								EN
							</button>
						</div>
					</div>
					<HighlightVideo
						video={highlightVideo}
						isBioOpen={showBio}
						isFullScreen={isFullScreen}
						fullscreenRequired={fullscreenRequired}
						requireFullscreen={requireFullscreen}
						setCurrentlyPlaying={setCurrentlyPlaying}
						currentlyPlaying={currentlyPlaying}
						setFullScreen={setFullScreen}
						isNavFocused={isNavFocused}
					/>
					<CarouselRow
						videos={gridVideos}
						isBioOpen={showBio}
						isFullScreen={isFullScreen}
						requireFullscreen={requireFullscreen}
						fullscreenRequired={fullscreenRequired}
						setCurrentlyPlaying={setCurrentlyPlaying}
						currentlyPlaying={currentlyPlaying}
						setFullScreen={setFullScreen}
					/>
					{/* <VideoRoll videos={videos} isBioOpen={showBio} /> */}
				</>
			)}
			{/*<h1 className="director-name col-start-1 row-start-2 md:col-end-3 z-10 w-min justify-self-center">{name}</h1>*/}
		</>
	)
}

const DirectorPage = ({ data, location }) => {
	return (
		<Layout location={location}>
			<Helmet
				title={`${data.markdownRemark.frontmatter.name} • Central Content`}
			>
				<meta
					name="description"
					content={data.markdownRemark.frontmatter.descriptionEn}
				/>
			</Helmet>
			<DirectorPageTemplate
				name={data.markdownRemark.frontmatter.name}
				videos={data.markdownRemark.frontmatter.directorVideos}
				descriptionPt={data.markdownRemark.frontmatter.descriptionPt}
				descriptionEn={data.markdownRemark.frontmatter.descriptionEn}
			/>
		</Layout>
	)
}

export default DirectorPage

export const data = graphql`
	query DirectorPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			frontmatter {
				name
				descriptionPt
				descriptionEn
				directorVideos {
					video {
						id
						frontmatter {
							title
							director
							desktopSource {
								source
								poster
							}
							mobileSource {
								source
								poster
							}
							fullSource
						}
					}
				}
			}
		}
	}
`
