import React, { useState, useRef, useEffect, useContext } from 'react'
import ReactPlayer from 'react-player'
import { NavFocusContext } from './Layout'
import screenfull from 'screenfull'

const GridVideo = ({
	fullSource,
	desktopSource,
	title,
	director,
	uid,
	className,
	captionClassName,
	isBioOpen,
	isFullScreen,
	fullscreenRequired,
	requireFullscreen,
	currentlyPlaying,
	setCurrentlyPlaying,
}) => {
	const [isHovered, setIsHovered] = useState(false)
	const isNavFocused = useContext(NavFocusContext)
	const playing = currentlyPlaying === uid
	useEffect(() => {
		if (isHovered && !isFullScreen && !fullscreenRequired) {
			setCurrentlyPlaying(uid)
		}
	}, [isHovered, isFullScreen, fullscreenRequired, setCurrentlyPlaying, uid])

	const figureEl = useRef(null)

	const requestFullscreen = (element) => {
		if (!isFullScreen && !isBioOpen && !isNavFocused && screenfull.isEnabled) {
			requireFullscreen(true)
			screenfull.request(element.current.children[0].children[0])
		}
	}

	return (
		<figure
			ref={figureEl}
			className={`${className}`}
			onMouseOver={() => setIsHovered(true)}
			onFocus={() => setIsHovered(true)}
			onMouseLeave={(event) =>
				event.relatedTarget?.tagName === 'VIDEO' && setIsHovered(false)
			}
			onBlur={(event) =>
				event.relatedTarget?.tagName === 'VIDEO' && setIsHovered(false)
			}
			onClick={(event) => {
				requestFullscreen(figureEl)
			}}
			onKeyPress={({ code }) => code === 'Enter' && requestFullscreen(figureEl)}
		>
			<ReactPlayer
				url={!isFullScreen ? desktopSource.source : fullSource}
				muted={!isFullScreen}
				loop
				playsinline={!isFullScreen}
				poster="https://i.vimeocdn.com/video/1193980890_1920x1080?r=pad"
				wrapper={'div'}
				width="100%"
				height="100%"
				pip={false}
				className="video-player video-cover"
				playing={playing}
				controls={isFullScreen}
				volume={0.5}
				config={{
					file: {
						attributes: {
							poster: desktopSource.poster,
						},
						hlsOptions: {
							startLevel: 4,
							// capLevelToPlayerSize: true
						},
					},
				}}
			/>
			<figcaption className={captionClassName}>
				<span className={`uppercase italic ${isFullScreen && 'hidden'}`}>
					{title}
				</span>
				{director && director}
			</figcaption>
		</figure>
	)
}

export default GridVideo
