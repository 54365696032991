import { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import React from 'react'
import GridVideo from './GridVideo'
import screenfull from 'screenfull'

// Import Swiper styles
import 'swiper/css'
import { Navigation } from 'swiper'
import 'swiper/css/navigation'
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

export const CarouselRow = ({
	videos,
	isBioOpen,
	currentlyPlaying,
	setCurrentlyPlaying,
	isFullScreen,
	setFullScreen,
	fullscreenRequired,
	requireFullscreen,
}) => {
	useEffect(() => {
		if (typeof document !== 'undefined' && screenfull.isEnabled) {
			screenfull.on('change', (event) => {
				if (screenfull.isFullscreen) {
					setFullScreen(true)
				} else {
					requireFullscreen(false)
					setFullScreen(false)
				}
			})
		}

		return () => {
			screenfull.off('change')
		}
	}, [])

	return (
		<Swiper
			spaceBetween={0}
			slidesPerView={4}
			onSlideChange={() => console.log('slide change')}
			onSwiper={(swiper) => console.log(swiper)}
			modules={[Navigation]}
			navigation
			className="max-h-min col-start-1 col-end-5 row-start-3 grid w-full max-w-full grid-cols-4"
		>
			{videos.map(
				({
					video: {
						frontmatter: { fullSource, desktopSource, title },
						id,
					},
				}) => (
					<SwiperSlide>
						<GridVideo
							fullSource={fullSource}
							desktopSource={desktopSource}
							isFullScreen={isFullScreen}
							title={title}
							isBioOpen={isBioOpen}
							uid={id}
							key={id}
							playing={currentlyPlaying === id}
							setCurrentlyPlaying={setCurrentlyPlaying}
							currentlyPlaying={currentlyPlaying}
							className="director-video relative w-full cursor-pointer bg-black"
							captionClassName="hidden w-full font-light capitalize justify-center absolute top-1/2 first:top-1/4 text-3xl text-white z-100"
							fullscreenRequired={fullscreenRequired}
							requireFullscreen={requireFullscreen}
						/>
					</SwiperSlide>
				)
			)}
		</Swiper>
	)
}

export default CarouselRow
