import GridVideo from './GridVideo'
import React from 'react'

const HighlightVideo = ({
	video,
	isFullScreen,
	fullscreenRequired,
	requireFullscreen,
	isBioOpen,
	currentlyPlaying,
	setCurrentlyPlaying,
	isNavFocused,
}) => {
	const {
		video: {
			frontmatter: { fullSource, desktopSource, title },
			id,
		},
	} = video

	return (
		<GridVideo
			fullSource={fullSource}
			desktopSource={desktopSource}
			isFullScreen={isFullScreen}
			title={title}
			isBioOpen={isBioOpen}
			uid={id}
			key={id}
			className="director-video relative w-full cursor-pointer bg-black"
			captionClassName={`hidden capitalize font-light absolute top-1/2 first:top-1/4 left-[5rem] text-6xl text-white z-100 ${
				isNavFocused || isBioOpen ? '!hidden' : ''
			}`}
			fullscreenRequired={fullscreenRequired}
			requireFullscreen={requireFullscreen}
			currentlyPlaying={currentlyPlaying}
			setCurrentlyPlaying={setCurrentlyPlaying}
		/>
	)
}

export default HighlightVideo
